import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "../views/Layout/DashboardLayout.vue";

// Pallet Pages
const TapList = () => import("../views/Pages/TapList.vue");
const FoodMenu = () => import("../views/Pages/FoodMenu.vue");
// const Events = () => import("../views/Pages/Events.vue");
const PalletPerks = () => import("../views/Pages/PalletPerks.vue");
const GiftCards = () => import("../views/Pages/GiftCards.vue");
const Contact = () => import("../views/Pages/Contact.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: "food-menu",
    component: DashboardLayout,
    children: [
      {
        path: "tap-list",
        name: "Tap List",
        component: TapList,
      },
      {
        path: "food-menu",
        name: "Food Menu",
        component: FoodMenu,
      },
      {
        path: "pallet-perks",
        name: "Pallet Perks",
        component: PalletPerks,
      },
      {
        path: "gift-cards",
        name: "Gift Cards",
        component: GiftCards,
      },
      {
        path: "contact",
        name: "Contact",
        component: Contact,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
