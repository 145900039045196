<template>
  <v-navigation-drawer
    width="100%"
    fixed
    app
    floating
    touchless
    :expand-on-hover="mini"
    :value="drawer"
    :right="$vuetify.rtl"
    class="my-4 ms-4 border-radius-lg"
    :class="!$vuetify.breakpoint.mobile ? '' : 'bg-white'"
    :data-color="sidebarColor"
    :data-theme="sidebarTheme"
  >
    <v-list-item class="pa-0">
      <v-list-item-content class="pa-0">
        <v-list-item-title
          class="title d-flex justify-center align-center mb-0"
        >
          <div class="v-navigation-drawer-brand pa-5 d-flex align-center">
            <a href="/">
              <v-img
                src="@/assets/img/the-pallet-header-logo.png"
                class="navbar-brand-img ms-3"
                width="117"
              >
              </v-img>
            </a>
          </div>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <hr class="horizontal dark mb-4" />

    <v-card
      to="/tap-list"
      class="card-shadow pa-4 border-radius-md mx-2 z-index-2"
      @click="closeDrawer()"
    >
      <div class="d-flex align-center">
        <v-card
          color="#fdb826"
          dark
          height="24"
          width="24"
          class="
            d-flex
            flex-shrink-0
            justify-center
            align-center
            card-shadow
            mr-4
            border-radius-sm
            z-index-2
          "
        >
          <v-icon>fas fa-beer</v-icon>
        </v-card>
        <div>
          <h6 class="text-h6 text-typo font-weight-bold">Tap List</h6>
        </div>
      </div>
    </v-card>

    <v-card
      to="/food-menu"
      class="card-shadow mt-3 pa-4 border-radius-md mx-2 z-index-2"
      @click="closeDrawer()"
    >
      <div class="d-flex align-center">
        <v-card
          color="#fdb826"
          dark
          height="24"
          width="24"
          class="
            d-flex
            flex-shrink-0
            justify-center
            align-center
            card-shadow
            mr-4
            border-radius-sm
            z-index-2
          "
        >
          <v-icon>fas fa-utensils</v-icon>
        </v-card>
        <div>
          <h6 class="text-h6 text-typo font-weight-bold">Food Menu</h6>
        </div>
      </div>
    </v-card>

    <v-card
      href="https://www.facebook.com/thepalletbarwv/events"
      target="_blank"
      class="card-shadow mt-3 pa-4 border-radius-md mx-2 z-index-2"
      @click="closeDrawer()"
    >
      <div class="d-flex align-center">
        <v-card
          color="#fdb826"
          dark
          height="24"
          width="24"
          class="
            d-flex
            flex-shrink-0
            justify-center
            align-center
            card-shadow
            mr-4
            border-radius-sm
            z-index-2
          "
        >
          <v-icon>fas fa-calendar-alt</v-icon>
        </v-card>
        <div>
          <h6 class="text-h6 text-typo font-weight-bold">Events</h6>
        </div>
      </div>
    </v-card>

    <v-card
      to="/pallet-perks"
      class="card-shadow mt-3 pa-4 border-radius-md mx-2 z-index-2"
      @click="closeDrawer()"
    >
      <div class="d-flex align-center">
        <v-card
          color="#fdb826"
          dark
          height="24"
          width="24"
          class="
            d-flex
            flex-shrink-0
            justify-center
            align-center
            card-shadow
            mr-4
            border-radius-sm
            z-index-2
          "
        >
          <v-icon>fas fa-id-card</v-icon>
        </v-card>
        <div>
          <h6 class="text-h6 text-typo font-weight-bold">Pallet Perks</h6>
        </div>
      </div>
    </v-card>

    <v-card
      to="/gift-cards"
      class="card-shadow mt-3 pa-4 border-radius-md mx-2 z-index-2"
      @click="closeDrawer()"
    >
      <div class="d-flex align-center">
        <v-card
          color="#fdb826"
          dark
          height="24"
          width="24"
          class="
            d-flex
            flex-shrink-0
            justify-center
            align-center
            card-shadow
            mr-4
            border-radius-sm
            z-index-2
          "
        >
          <v-icon>fas fa-gift</v-icon>
        </v-card>
        <div>
          <h6 class="text-h6 text-typo font-weight-bold">Gift Cards</h6>
        </div>
      </div>
    </v-card>

    <v-card
      to="/contact"
      class="card-shadow mt-3 pa-4 border-radius-md mx-2 z-index-2"
      @click="closeDrawer()"
    >
      <div class="d-flex align-center">
        <v-card
          color="#fdb826"
          dark
          height="24"
          width="24"
          class="
            d-flex
            flex-shrink-0
            justify-center
            align-center
            card-shadow
            mr-4
            border-radius-sm
            z-index-2
          "
        >
          <v-icon>fas fa-concierge-bell</v-icon>
        </v-card>
        <div>
          <h6 class="text-h6 text-typo font-weight-bold">Contact</h6>
        </div>
      </div>
    </v-card>

    <hr class="horizontal dark my-4" />

    <v-card
      class="pa-0 border-radius-lg mt-7 mb-9 mx-4"
      :style="`background-image: url(${require('../assets/img/curved-images/white-curved.jpeg')}); background-size: cover;`"
    >
      <span
        class="mask opacity-8 border-radius-lg"
        :class="`bg-gradient-` + sidebarColor"
      ></span>
      <div class="px-4 py-4 position-relative">
        <v-avatar
          color="bg-white"
          class="shadow"
          width="32"
          height="32"
          min-width="32"
          rounded
        >
          <v-icon
            size="20"
            class="text-gradient"
            :class="`text-` + sidebarColor"
          >
            fas fa-mobile-alt
          </v-icon>
        </v-avatar>
        <div class="docs-info">
          <v-card-text class="text-white text-h6 ps-0 pb-0"
            >Online Ordering!</v-card-text
          >
          <v-card-subtitle class="text-white text-xs font-weight-bold pt-0 ps-0"
            >For Take-Out Orders</v-card-subtitle
          >
          <v-btn
            elevation="0"
            :ripple="false"
            small
            href="https://www.toasttab.com/the-pallet"
            target="_blank"
            class="
              font-weight-bolder
              text-uppercase
              btn-white
              py-4
              px-6
              rounded-md
              me-2
              my-2
              w-100
            "
            color="#fff"
            >PLACE ORDER NOW
          </v-btn>
        </div>
      </div>
    </v-card>

    <hr class="horizontal dark my-4" />

    <div class="d-flex flex-column align-center mb-4">
      <a
        href="https://www.google.com/maps/place/The+Pallet+Bar/@38.4485584,-81.9417091,17z/data=!3m1!4b1!4m5!3m4!1s0x8848b574f82f6b81:0x6e99fb88391f3d24!8m2!3d38.4485665!4d-81.939526"
        target="_blank"
        style="color: #fdb826"
      >
        3999 Teays Valley Road
      </a>
      <a href="tel:6812357339" style="color: #fdb826">681-235-7339</a>
      <a href="mailto:beer@thepallet.bar" style="color: #fdb826"
        >beer@thepallet.bar</a
      >
      <span>M-W 5PM-10PM</span>
      <span>TH 4-10PM</span>
      <span>FR 4-12AM</span>
      <span>SA 12-12AM</span>
      <span>SU 2-8PM</span>
    </div>
  </v-navigation-drawer>
</template>
<script>
export default {
  name: "drawer",
  props: {
    drawer: {
      type: Boolean,
      default: null,
    },
    sidebarColor: {
      type: String,
      default: "warning",
    },
    sidebarTheme: {
      type: String,
      default: "transparent",
    },
  },
  data: () => ({
    mini: false,
    togglerActive: false,
    thirdLevelSimple: [
      "Third level menu",
      "Just another link",
      "One last link",
    ],
  }),
  methods: {
    closeDrawer() {
      if (this.$vuetify.breakpoint.mobile == true) {
        this.$emit("drawer-toggle", false);
      }
    },
    listClose(event) {
      let items;
      let headers;
      let groups;
      let currentEl;

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link.item-active"
        )
      ) {
        items = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link.item-active"
        );
      }

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group__header.v-list-item--active"
        )
      ) {
        headers = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group__header.v-list-item--active"
        );
      }

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group .v-list .v-list-group.v-list-group--active, .mb-0.v-list-item.v-list-item--link .v-list-group.v-list-group--active"
        )
      ) {
        groups = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group .v-list .v-list-group.v-list-group--active, .mb-0.v-list-item.v-list-item--link .v-list-group.v-list-group--active"
        );
      }

      if (
        event.target.closest(
          ".mb-0.v-list-item.v-list-item--link .v-list-item__content.ms-6 .v-list-group"
        )
      ) {
        currentEl = event.target.closest(
          ".mb-0.v-list-item.v-list-item--link .v-list-item__content.ms-6 .v-list-group"
        );
      }

      if (items != null) {
        for (var i = 0; i < items.length; i++) {
          items[i].classList.remove("item-active");
        }
      }

      if (headers != null) {
        for (var j = 0; j < headers.length; j++) {
          headers[j].classList.remove(
            "v-list-item--active",
            "item-active",
            "primary--text"
          );
          headers[j].setAttribute("aria-expanded", false);
        }
      }

      if (groups != null) {
        for (var k = 0; k < groups.length; k++) {
          groups[k].classList.remove("v-list-group--active", "primary--text");
        }
      }

      if (event.target.closest(".mb-0.v-list-item.v-list-item--link")) {
        event.target
          .closest(".mb-0.v-list-item.v-list-item--link")
          .classList.add("item-active");
      }

      if (currentEl != null) {
        currentEl
          .querySelector(".v-list-group__header")
          .classList.add("v-list-item--active", "item-active");
      }
    },
  },
};
</script>
