<template>
  <v-app-bar
    :color="background"
    height="auto"
    class="mt-5 px-0 mx-6 border-radius-xl toolbar-content-padding-y-none"
    :class="
      navbarFixed
        ? 'position-sticky blur shadow-blur top-1 z-index-sticky py-2'
        : ''
    "
    flat
    style="box-shadow: 10px 10px 20px rgb(0 0 0 / 20%) !important"
  >
    <v-row class="py-1">
      <v-col v-if="$vuetify.breakpoint.mobile" class="d-flex">
        <div class="d-flex justify-left align-center">
          <v-btn
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-600
              text-capitalize
              drawer-toggler
              py-3
              px-0
              rounded-sm
            "
            v-if="$vuetify.breakpoint.mobile"
            color="transparent"
            @click="drawerClose"
          >
            <div class="drawer-toggler-inner">
              <i class="drawer-toggler-line text-body"></i>
              <i class="drawer-toggler-line text-body"></i>
              <i class="drawer-toggler-line text-body"></i>
            </div>
          </v-btn>
        </div>
        <div
          class="d-flex justify-center align-center font-weight-bolder text-lg"
          style="cursor: pointer"
          @click="drawerClose"
        >
          MENU
        </div>
        <div style="width: 100%" class="d-flex justify-center">
          <a href="/">
            <img
              src="@/assets/img/the-pallet-header-logo-main.png"
              height="55"
            />
          </a>
        </div>
      </v-col>
    </v-row>
  </v-app-bar>
</template>
<script>
export default {
  name: "app-bar",
  props: {
    background: String,
    hasBg: Boolean,
    linkColor: String,
    toggleActive: Boolean,
    navbarFixed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      drawer: false,
      togglerActive: false,
      dropdown: [
        {
          avatar: require("@/assets/img/team-2.jpg"),
          title:
            '<span class="font-weight-bold">New message</span> from Laur</h6>',
          time: "13 minutes ago",
        },
        {
          image: require("@/assets/img/small-logos/logo-spotify.svg"),
          title:
            '<span class="font-weight-bold">New album</span> by Travis Scott',
          time: "1 day",
        },
        {
          icon: require("@/assets/img/icons/card.svg"),
          title: "Payment successfully completed",
          time: "2 days",
        },
      ],
    };
  },
  methods: {
    drawerClose() {
      this.togglerActive = !this.togglerActive;
      this.$emit("drawer-toggle", true);
    },
    minifyDrawer() {
      this.togglerActive = !this.togglerActive;
      this.mini = !this.mini;
      const body = document.getElementsByTagName("body")[0];

      if (body.classList.contains("drawer-mini")) {
        body.classList.remove("drawer-mini");
      } else {
        body.classList.add("drawer-mini");
      }
    },
  },
  watch: {
    toggleActive(val) {
      this.togglerActive = val;
    },
  },
};
</script>

<style scoped>
.drawer-toggler-line {
  width: 36px;
  height: 4px;
}
.drawer-toggler:not(.active) .drawer-toggler-line:first-child,
.drawer-toggler:not(.active) .drawer-toggler-line:last-child {
  width: 30px;
  height: 4px;
  transform: translateX(6px);
}
</style>
