<template>
  <span>
    <template v-if="auth">
      <v-footer color="transparent" class="mt-10 pt-10 pb-5">
        <v-card class="flex" color="transparent">
          <v-card-text class="px-0">
            <v-row>
              <v-col cols="12" lg="8" class="mb-2 mx-auto text-center">
                <a
                  v-for="item in footer"
                  :key="item.linkName"
                  :href="item.link"
                  class="text-decoration-none text-secondary ls-0 mx-4 text-md"
                  target="_blank"
                  >{{ item.linkName }}</a
                >
              </v-col>
              <v-col cols="8" class="mx-auto text-center">
                <v-btn
                  v-for="item in icons"
                  :key="item.icon"
                  icon
                  width="31"
                  :ripple="false"
                  class="mx-3 text-secondary no-default-hover"
                  link
                  href="javascript:;"
                  target="_blank"
                >
                  <v-icon size="18">{{ item.icon }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8" class="mx-auto text-center">
                <p class="copyright text-secondary text-md ls-0">
                  Copyright © {{ new Date().getFullYear() }}
                  Soft by
                  <a
                    href="https://www.creative-tim.com"
                    class="
                      text-decoration-none
                      btn-hover
                      text-primary text-body-2
                      ls-0
                    "
                    target="_blank"
                    >Creative Tim</a
                  >
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-footer>
    </template>

    <template v-else>
      <v-footer color="transparent" class="px-6">
        <v-card class="flex" color="transparent">
          <v-card-text class="px-0">
            <v-row>
              <v-col
                xs="12"
                md="4"
                cols="12"
                class="d-flex justify-center ma-0 pa-0"
              >
                <a
                  href="https://www.google.com/maps/place/The+Pallet+Bar/@38.4485584,-81.9417091,17z/data=!3m1!4b1!4m5!3m4!1s0x8848b574f82f6b81:0x6e99fb88391f3d24!8m2!3d38.4485665!4d-81.939526"
                  target="_blank"
                  style="color: #fdb826"
                >
                  3999 Teays Valley Road
                </a>
              </v-col>
              <v-col
                xs="12"
                md="4"
                cols="12"
                class="d-flex justify-center ma-0 pa-0"
              >
                <a href="tel:6812357339" class="mx-6" style="color: #fdb826"
                  >681-235-7339</a
                >
              </v-col>
              <v-col
                xs="12"
                md="4"
                cols="12"
                class="d-flex justify-center ma-0 pa-0"
              >
                <a href="mailto:beer@thepallet.bar" style="color: #fdb826"
                  >beer@thepallet.bar</a
                >
              </v-col>
            </v-row>
            <v-row class="d-none d-md-flex">
              <v-col cols="12" class="d-flex justify-center align-center">
                M-W 5PM-10PM, TH 4-10PM, FR 4-12AM, SA 12-12AM, SU 2-8PM
              </v-col>
            </v-row>
            <v-row class="d-xs-flex d-md-none pt-2">
              <v-col
                cols="12"
                class="d-flex justify-center align-center ma-0 pa-0"
              >
                M-W 5PM-10PM, TH 4-10PM, FR 4-12AM
              </v-col>
              <v-col
                cols="12"
                class="d-flex justify-center align-center ma-0 pa-0"
              >
                SA 12-12AM, SU 2-8PM
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="d-flex justify-center align-center">
                © {{ new Date().getFullYear() }}, The Pallet, LLC
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-footer>
    </template>
  </span>
</template>
<script>
export default {
  name: "content-footer",
  props: {
    auth: Boolean,
  },
  data() {
    return {
      footer: [
        {
          linkName: "Creative Tim",
          link: "https://www.creative-tim.com/",
        },
        {
          linkName: "About Us",
          link: "https://www.creative-tim.com/presentation",
        },
        {
          linkName: "Blog",
          link: "http://blog.creative-tim.com/",
        },
        {
          linkName: "License",
          link: "https://www.creative-tim.com/license",
        },
      ],
      icons: [
        {
          icon: "fab fa-dribbble",
        },
        {
          icon: "fab fa-twitter",
        },
        {
          icon: "fab fa-instagram",
        },
        {
          icon: "fab fa-pinterest",
        },
        {
          icon: "fab fa-github",
        },
      ],
    };
  },
};
</script>
